import type { RouteRecordRaw } from 'vue-router';
import { checkUserAuthentication } from '@/utils/router.utils';

export enum AdminRouteNames {
    Home = 'admin-home',
    TrialCode = 'admin-tools-trial-code',
    UserListing = 'admin-user-listing',
    UserCreate = 'admin-user-create',
    UserEdit = 'admin-user-edit',
    CompaniesListing = 'admin-companies-listing',
    BrandListing = 'admin-brand-listing',
    BrandEdit = 'admin-brand-edit',
    BrandCreate = 'admin-brand-create',
    CompanyCreate = 'admin-company-create',
    CompanyEdit = 'admin-company-edit',
    ResellersListing = 'admin-reseller-listing',
    ResellerCreate = 'admin-reseller-create',
    ResellerEdit = 'admin-reseller-edit',
    ResellerStats = 'admin-reseller-stats',
    ResellerStatsCompanies = 'admin-reseller-stats-companies',
    BundlesListing = 'admin-bundles-listing',
    BundleCreate = 'admin-bundle-create',
    BundleEdit = 'admin-bundle-edit',
    PackagesListing = 'admin-packages-listing',
    PackageCreate = 'admin-package-create',
    PackageEdit = 'admin-package-edit',
    ServicesListing = 'admin-services-listing',
    ServiceCreate = 'admin-service-create',
    ServiceEdit = 'admin-service-edit',
    Settings = 'admin-settings',
    Logs = 'admin-logs',
    Exports = 'admin-exports',
    NotificationListing = 'admin-notification-listing',
    NotificationEdit = 'admin-notification-edit',
    NotificationCreate = 'admin-notification-create',
    RolePresetListing = 'admin-role-preset-listing',
    RolePresetEdit = 'admin-role-preset-edit',
    RolePresetCreate = 'admin-role-preset-create',
    DecoderTools = 'admin-tools-decoder',
    TrialCodeListing = 'admin-trial-code-listing',
}

const AdminRoutes: RouteRecordRaw[] = [
    {
        path: '/admin',
        children: [
            {
                path: '',
                name: AdminRouteNames.Home,
                components: {
                    sidebar: () => import('@/admin/components/layout/AdminSidebar.vue'),
                    default: () => import('@/admin/pages/HomePage.vue'),
                },
            },
            {
                path: 'tools',
                children: [
                    {
                        path: 'trial-code',
                        name: AdminRouteNames.TrialCode,
                        components: {
                            sidebar: () => import('@/admin/components/layout/AdminSidebar.vue'),
                            default: () => import('@/admin/pages/tools/TrialCode.vue'),
                        },
                    },
                ],
            },
            {
                path: 'users',
                children: [
                    {
                        path: '',
                        name: AdminRouteNames.UserListing,
                        components: {
                            sidebar: () => import('@/admin/components/layout/AdminSidebar.vue'),
                            default: () => import('@/admin/pages/users/ListingPage.vue'),
                        },
                    },
                    {
                        path: 'create',
                        name: AdminRouteNames.UserCreate,
                        components: {
                            sidebar: () => import('@/admin/components/layout/AdminSidebar.vue'),
                            default: () => import('@/admin/pages/users/EditPage.vue'),
                        },
                    },
                    {
                        path: ':id(\\d+)',
                        name: AdminRouteNames.UserEdit,
                        components: {
                            sidebar: () => import('@/admin/components/layout/AdminSidebar.vue'),
                            default: () => import('@/admin/pages/users/EditPage.vue'),
                        },
                    },
                ],
            },
            {
                path: 'companies',
                children: [
                    {
                        path: '',
                        name: AdminRouteNames.CompaniesListing,
                        components: {
                            sidebar: () => import('@/admin/components/layout/AdminSidebar.vue'),
                            default: () => import('@/admin/pages/company/ListingPage.vue'),
                        },
                    },
                    {
                        path: 'create',
                        name: AdminRouteNames.CompanyCreate,
                        components: {
                            sidebar: () => import('@/admin/components/layout/AdminSidebar.vue'),
                            default: () => import('@/admin/pages/company/EditPage.vue'),
                        },
                    },
                    {
                        path: ':id(\\d+)',
                        name: AdminRouteNames.CompanyEdit,
                        components: {
                            sidebar: () => import('@/admin/components/layout/AdminSidebar.vue'),
                            default: () => import('@/admin/pages/company/EditPage.vue'),
                        },
                    },
                ],
            },
            {
                path: 'brand',
                children: [
                    {
                        path: '',
                        name: AdminRouteNames.BrandListing,
                        components: {
                            sidebar: () => import('@/admin/components/layout/AdminSidebar.vue'),
                            default: () => import('@/admin/pages/brand/ListingPage.vue'),
                        },
                    },
                    {
                        path: 'create',
                        name: AdminRouteNames.BrandCreate,
                        components: {
                            sidebar: () => import('@/admin/components/layout/AdminSidebar.vue'),
                            default: () => import('@/admin/pages/brand/EditPage.vue'),
                        },
                    },
                    {
                        path: ':id(\\d+)',
                        name: AdminRouteNames.BrandEdit,
                        components: {
                            sidebar: () => import('@/admin/components/layout/AdminSidebar.vue'),
                            default: () => import('@/admin/pages/brand/EditPage.vue'),
                        },
                    },
                ],
            },
            {
                path: 'resellers',
                children: [
                    {
                        path: '',
                        name: AdminRouteNames.ResellersListing,
                        components: {
                            sidebar: () => import('@/admin/components/layout/AdminSidebar.vue'),
                            default: () => import('@/admin/pages/reseller/ListingPage.vue'),
                        },
                    },
                    {
                        path: 'create',
                        name: AdminRouteNames.ResellerCreate,
                        components: {
                            sidebar: () => import('@/admin/components/layout/AdminSidebar.vue'),
                            default: () => import('@/admin/pages/reseller/EditPage.vue'),
                        },
                    },
                    {
                        path: ':id(\\d+)',
                        name: AdminRouteNames.ResellerEdit,
                        components: {
                            sidebar: () => import('@/admin/components/layout/AdminSidebar.vue'),
                            default: () => import('@/admin/pages/reseller/EditPage.vue'),
                        },
                    },
                    {
                        path: ':id(\\d+)/stats',
                        name: AdminRouteNames.ResellerStats,
                        components: {
                            sidebar: () => import('@/admin/components/layout/AdminSidebar.vue'),
                            default: () => import('@/admin/pages/reseller/StatsPage.vue'),
                        },
                    },
                    {
                        path: ':id(\\d+)/stats/companies',
                        name: AdminRouteNames.ResellerStatsCompanies,
                        components: {
                            sidebar: () => import('@/admin/components/layout/AdminSidebar.vue'),
                            default: () => import('@/admin/pages/reseller/StatsCompanyPage.vue'),
                        },
                    },
                ],
            },
            {
                path: 'bundles',
                children: [
                    {
                        path: '',
                        name: AdminRouteNames.BundlesListing,
                        components: {
                            sidebar: () => import('@/admin/components/layout/AdminSidebar.vue'),
                            default: () => import('@/admin/pages/bundle/ListingPage.vue'),
                        },
                    },
                    {
                        path: 'create',
                        name: AdminRouteNames.BundleCreate,
                        components: {
                            sidebar: () => import('@/admin/components/layout/AdminSidebar.vue'),
                            default: () => import('@/admin/pages/bundle/EditPage.vue'),
                        },
                    },
                    {
                        path: ':id(\\d+)',
                        name: AdminRouteNames.BundleEdit,
                        components: {
                            sidebar: () => import('@/admin/components/layout/AdminSidebar.vue'),
                            default: () => import('@/admin/pages/bundle/EditPage.vue'),
                        },
                    },
                ],
            },
            {
                path: 'packages',
                children: [
                    {
                        path: '',
                        name: AdminRouteNames.PackagesListing,
                        components: {
                            sidebar: () => import('@/admin/components/layout/AdminSidebar.vue'),
                            default: () => import('@/admin/pages/package/ListingPage.vue'),
                        },
                    },
                    {
                        path: 'create',
                        name: AdminRouteNames.PackageCreate,
                        components: {
                            sidebar: () => import('@/admin/components/layout/AdminSidebar.vue'),
                            default: () => import('@/admin/pages/package/EditPage.vue'),
                        },
                    },
                    {
                        path: ':id(\\d+)',
                        name: AdminRouteNames.PackageEdit,
                        components: {
                            sidebar: () => import('@/admin/components/layout/AdminSidebar.vue'),
                            default: () => import('@/admin/pages/package/EditPage.vue'),
                        },
                    },
                ],
            },
            {
                path: 'services',
                children: [
                    {
                        path: '',
                        name: AdminRouteNames.ServicesListing,
                        components: {
                            sidebar: () => import('@/admin/components/layout/AdminSidebar.vue'),
                            default: () => import('@/admin/pages/service/ListingPage.vue'),
                        },
                    },
                    {
                        path: 'create',
                        name: AdminRouteNames.ServiceCreate,
                        components: {
                            sidebar: () => import('@/admin/components/layout/AdminSidebar.vue'),
                            default: () => import('@/admin/pages/service/EditPage.vue'),
                        },
                    },
                    {
                        path: ':id(\\d+)',
                        name: AdminRouteNames.ServiceEdit,
                        components: {
                            sidebar: () => import('@/admin/components/layout/AdminSidebar.vue'),
                            default: () => import('@/admin/pages/service/EditPage.vue'),
                        },
                    },
                ],
            },
            {
                path: 'settings',
                children: [
                    {
                        path: '',
                        name: AdminRouteNames.Settings,
                        components: {
                            sidebar: () => import('@/admin/components/layout/AdminSidebar.vue'),
                            default: () => import('@/admin/pages/settings/SettingsPage.vue'),
                        },
                    },
                ],
            },
            {
                path: 'logs',
                children: [
                    {
                        path: '',
                        name: AdminRouteNames.Logs,
                        components: {
                            sidebar: () => import('@/admin/components/layout/AdminSidebar.vue'),
                            default: () => import('@/admin/pages/log/LogPage.vue'),
                        },
                    },
                ],
            },
            {
                path: 'exports',
                children: [
                    {
                        path: '',
                        name: AdminRouteNames.Exports,
                        components: {
                            sidebar: () => import('@/admin/components/layout/AdminSidebar.vue'),
                            default: () => import('@/admin/pages/export/ExportPage.vue'),
                        },
                    },
                ],
            },
            {
                path: 'notifications',
                children: [
                    {
                        path: '',
                        name: AdminRouteNames.NotificationListing,
                        components: {
                            sidebar: () => import('@/admin/components/layout/AdminSidebar.vue'),
                            default: () => import('@/admin/pages/notification/ListingPage.vue'),
                        },
                    },
                    {
                        path: ':id(\\d+)',
                        name: AdminRouteNames.NotificationEdit,
                        components: {
                            sidebar: () => import('@/admin/components/layout/AdminSidebar.vue'),
                            default: () => import('@/admin/pages/notification/EditPage.vue'),
                        },
                    },
                    {
                        path: 'create',
                        name: AdminRouteNames.NotificationCreate,
                        components: {
                            sidebar: () => import('@/admin/components/layout/AdminSidebar.vue'),
                            default: () => import('@/admin/pages/notification/EditPage.vue'),
                        },
                    },
                ],
            },
            {
                path: 'role-presets',
                children: [
                    {
                        path: '',
                        name: AdminRouteNames.RolePresetListing,
                        components: {
                            sidebar: () => import('@/admin/components/layout/AdminSidebar.vue'),
                            default: () => import('@/admin/pages/role-preset/ListingPage.vue'),
                        },
                    },
                    {
                        path: ':id(\\d+)',
                        name: AdminRouteNames.RolePresetEdit,
                        components: {
                            sidebar: () => import('@/admin/components/layout/AdminSidebar.vue'),
                            default: () => import('@/admin/pages/role-preset/EditPage.vue'),
                        },
                    },
                    {
                        path: 'create',
                        name: AdminRouteNames.RolePresetCreate,
                        components: {
                            sidebar: () => import('@/admin/components/layout/AdminSidebar.vue'),
                            default: () => import('@/admin/pages/role-preset/EditPage.vue'),
                        },
                    },
                ],
            },
            {
                path: 'tools',
                children: [
                    {
                        path: 'decoder',
                        name: AdminRouteNames.DecoderTools,
                        components: {
                            sidebar: () => import('@/admin/components/layout/AdminSidebar.vue'),
                            default: () => import('@/admin/pages/tools/DecoderPage.vue'),
                        },
                    },
                ],
            },
            {
                path: 'subscription',
                children: [
                    {
                        path: 'trial-codes',
                        name: AdminRouteNames.TrialCodeListing,
                        components: {
                            sidebar: () => import('@/admin/components/layout/AdminSidebar.vue'),
                            default: () => import('@/admin/pages/subscription/trial-codes/ListingPage.vue'),
                        },
                    },
                ],
            },
        ],
    },
].map((route) => ({ ...route, beforeEnter: checkUserAuthentication }) as RouteRecordRaw);

export default AdminRoutes;
