import { defineAsyncComponent } from 'vue';
import LoadingOverlay from '@/components/shared/LoadingOverlay.vue';
import type { AppRoute } from '@/models/app.model';
import { RouteNames } from '@/enums/router.enum';
import { UserRoles } from '@/enums/role.enum';

export enum Categories {
    Editor = 'editor',
    Api = 'api',
    Widget = 'widget',
}

export enum ProductSolutions {
    LocationInsight = 'location-insight',
    Estimator = 'estimator',
    Api = 'api',
}

export enum ApiSolutions {
    Estate = 'estate',
    Isochrone = 'isochrone',
    RealtyValuation = 'realty-valuation',
    Cadastral = 'cadastral',
    CadastralMap = 'cadastral-map',
    PoiMap = 'poi-map',
    LegacyMap = 'legacy-map',
    PriceMap = 'price-map',
    Insee = 'insee',
    Scoring = 'scoring',
}

export type Solutions = Omit<ProductSolutions, 'api'> | ApiSolutions;

export const SolutionEditionPanelMapping = {
    [ProductSolutions.Estimator]: defineAsyncComponent({
        loader: () => import('@/components/solution/estimator/EditionPanel.vue'),
        loadingComponent: LoadingOverlay,
        delay: 0,
    }),
    [ProductSolutions.LocationInsight]: defineAsyncComponent({
        loader: () => import('@/components/solution/location-insight/EditionPanel.vue'),
        loadingComponent: LoadingOverlay,
        delay: 0,
    }),
    [ProductSolutions.Api]: defineAsyncComponent({
        loader: () => import('@/components/solution/api/EditionPanel.vue'),
        loadingComponent: LoadingOverlay,
        delay: 0,
    }),
};

export const SolutionIconMapping = {
    [ProductSolutions.Estimator]: 'sym_o_insights',
    [ProductSolutions.LocationInsight]: 'sym_o_group',
    [ProductSolutions.Api]: 'sym_o_webhook',
};

export const SolutionTabs: AppRoute[] = [
    {
        icon: 'sym_o_package_2',
        label: 'Mes produits',
        to: { name: RouteNames.SolutionLanding },
        id: RouteNames.SolutionLanding,
    },
    {
        icon: 'sym_o_vpn_key',
        label: 'Clés APIs',
        to: { name: RouteNames.SolutionAuthKey },
        id: RouteNames.SolutionAuthKey,
        roles: [UserRoles.ROLE_SHOW_AUTH],
    },
    {
        icon: 'sym_o_book',
        label: 'Documentation',
        to: { name: RouteNames.SolutionUserGuide },
        id: RouteNames.SolutionUserGuide,
    },
];
