import type { App } from 'vue';

import AxiosPlugin from '@/plugins/axios';
import BusPlugin from '@/plugins/bus';
import headPlugin from '@/plugins/head';
import AxiosErrorHandler from '@/plugins/httpErrorHandler';
import I18nPlugin from '@/plugins/i18n';
import RecaptchaPlugin from '@/plugins/recaptcha';
import ResponsivePlugin from '@/plugins/responsive';

export default (app: App) => {
    app.use(AxiosPlugin);
    app.use(BusPlugin);
    app.use(headPlugin);
    app.use(AxiosErrorHandler);
    app.use(I18nPlugin);
    app.use(RecaptchaPlugin);
    app.use(ResponsivePlugin);
};
