import type { NavigationGuard } from 'vue-router';
import { useAuthenticationStore } from '@/stores/authentication.store';
import { useGlobalStore } from '@/stores/global.store';
import { ApplicationMode } from '@/models/app.model';
import { RouteNames } from '@/enums/router.enum';
import { UserRoles } from '@/enums/role.enum';

export const checkSidebarQueryParam: NavigationGuard = (to, from, next) => {
    const globalStore = useGlobalStore();
    if (to.query.hideSidebar !== undefined) {
        globalStore.hideSidebarQueryParam = [null, '', 'true', '1'].includes(to.query.hideSidebar as string);
    }
    return next();
};

export const checkUserAuthentication: NavigationGuard = async (to, from, next) => {
    const globalStore = useGlobalStore();
    const authenticationStore = useAuthenticationStore();

    if (to.query.hasOwnProperty(ApplicationMode.Embedded)) {
        globalStore.appMode = to.query.embedded === 'true' ? ApplicationMode.Embedded : ApplicationMode.Standalone;
    }

    if (globalStore.appMode === ApplicationMode.Embedded) {
        if (to.name.toString().startsWith('admin')) {
            return next({ name: RouteNames.Home });
        }
        console.log('[DEBUG|GUARD] - Mode : Embedded');
        console.log('[DEBUG|GUARD] - Embedded : authenticating...');

        if (to.query?.authorization_code) {
            await authenticationStore.embeddedAuthenticate(to.query.authorization_code as string);
        } else {
            authenticationStore.loadFromStorage();
        }

        if (!authenticationStore.isAuthenticated) {
            globalStore.applicationError = 'Missing authorization_code';
            console.log('[DEBUG|GUARD] - Embedded : authentication failed');
        } else {
            console.log('[DEBUG|GUARD] - Embedded : authenticating success');
            await globalStore.fetchApplicationSettings();

            if (to.query?.authorization_code || to.query?.embedded) {
                delete to.query.authorization_code;
                delete to.query.embedded;
                return next(to);
            }
        }
    } else {
        if (!authenticationStore.isAuthenticated) {
            console.log('[DEBUG|GUARD] - Mode : Standalone');
            authenticationStore.loadFromStorage();
            // here to prevent fetching on route change
            if (authenticationStore.isAuthenticated) {
                console.log('[DEBUG|GUARD] - Fetching application settings');
                await globalStore.fetchApplicationSettings();

                if (authenticationStore.roles.includes(UserRoles.ROLE_ADMIN)) {
                    globalStore.isReady = true;
                    return next();
                }

                if (!globalStore.company.activeSubscription && from.name !== RouteNames.Subscribe) {
                    if (globalStore.company.subscriptions.length > 0) {
                        return next({ name: RouteNames.SettingsSubscription });
                    } else {
                        return next({ name: RouteNames.Subscribe });
                    }
                }

                if (!globalStore.company.activeSubscription && from.name !== RouteNames.SettingsSubscription) {
                    return next({ name: RouteNames.SettingsSubscription });
                }

                if (
                    to.name.toString().startsWith('admin') &&
                    !authenticationStore.roles.some((role) =>
                        [UserRoles.ROLE_ADMIN, UserRoles.ROLE_RESELLER_ADMIN].includes(role),
                    )
                ) {
                    return next({ name: RouteNames.Home });
                }
            }
        }

        if (!authenticationStore.isAuthenticated) {
            console.log('[DEBUG|GUARD] - Standalone : not authenticated - redirecting');

            globalStore.isReady = true;
            return next({ name: RouteNames.Login });
        }
    }

    globalStore.isReady = true;
    next();
};
